import React from "react";
import { LinkBtn } from "../Components/Button";
import { Container } from "../Components/Layout";
import Logo from "../Components/Logo";
import { Info, MainBox } from "../Components/Text";
import HeaderTitle from "../Components/Tilte";
import GAHoc from "../GA";
import styled from "styled-components";
const LinkBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  max-width: 400px;
`;

const Home = () => {
  const ToWho = () => {
    window.location.replace("https://linktr.ee/boogibooks");
  };
  const ToShell = () => {
    window.location.replace("https://bit.ly/boogibooks-intro");
  };
  return (
    <GAHoc>
      <Container style={{ height: "100%" }}>
        <HeaderTitle title="홈" />
        <MainBox>
          <Logo src="/logo.png" alt="logo" style={{ marginBottom: 30 }} />
          <Info>안녕하세요!</Info>
          <Info>저는 책읽는 거북이</Info>
          <Info>부기에요.</Info>
          <LinkBox>
            <LinkBtn onClick={ToWho}>부기에 대해 알아보기</LinkBtn>
            <LinkBtn onClick={ToShell}>독서모임 쉘하우스</LinkBtn>
          </LinkBox>
        </MainBox>
      </Container>
    </GAHoc>
  );
};
export default Home;
