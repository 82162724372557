import memberData from "../Data/member.json";
import React, { useEffect, useRef, useState } from "react";
import { Container, Divider } from "../Components/Layout";
import {
  Pre,
  TextInput,
  Info,
  HorizontalBox,
  VerticalBox,
  SectionTitle,
} from "../Components/Text";
import HeaderTitle from "../Components/Tilte";
import GAHoc from "../GA";
import Logo from "../Components/Logo";
import bookData from "../Data/books.json";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import ProgressBar from "../Components/ProgressBar";
import TitleSection from "../Components/TitleSection";
import Book from "../Components/Book";
import { expired } from "../Components/Utils";

const BooklistAugust = () => {
  const [name, setName] = useState("부기");
  const [progress, setProgress] = useState(0);
  const { month } = useParams();
  const { kr: month_kr, booklist } = bookData[month];
  const [dispState, setDispState] = useState(expired(month_kr[0]) ? 2 : 0);
  const progressUpdate = (num) => {
    if (num < 1.0005) setProgress(num);
  };
  const [pageDuration] = useState(2000);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispState]);

  useEffect(() => {
    const greeting = () => {
      const greetingTimerId = setTimeout(() => {
        progressUpdate(progress + 1 / 1000);

        if (progress >= 1) {
          setProgress(0);
          clearTimeout(greetingTimerId);
          setDispState(dispState + 1);
        }
      }, 1);
      return greetingTimerId;
    };
    const changeDisp = () => {
      const changeDispTimerID = setTimeout(() => {
        progressUpdate(progress + 1 / pageDuration);

        if (progress >= 1 && progress < 1.0005) {
          setProgress(0);
          clearTimeout(changeDispTimerID);
          setDispState(dispState + 1);
        }
      }, 1);
      return changeDispTimerID;
    };

    if (dispState === 0) {
      greeting();
      return () => clearTimeout(greeting());
    } else if (2 <= dispState && dispState <= 4) {
      changeDisp();
    }
  }, [dispState, progress, pageDuration]);
  const inputRef = useRef();
  useEffect(() => {
    if (dispState === 1) {
      inputRef.current.focus();
    }
    if (!expired(month_kr[0]) && name === "부기" && dispState > 1) {
      setDispState(1);
    }
  }, [name, dispState, month_kr]);

  const changeHandler = ({ target: { value } }) => {
    if (Object.keys(memberData).includes(value)) {
      setName(value);
      setDispState(2);
    }
  };
  const StateResponder = ({ state }) => {
    switch (state) {
      case 0:
        return <TitleSection month_kr={month_kr} />;
      case 1:
        return (
          <VerticalBox>
            <SectionTitle>{"사용자의 닉네임을"}</SectionTitle>
            <SectionTitle>{"입력하세요"}</SectionTitle>
            <TextInput
              ref={inputRef}
              type="text"
              onChange={changeHandler}
              placeholder={"닉네임"}
            />
          </VerticalBox>
        );
      case 2:
        return (
          <VerticalBox>
            <SectionTitle>안녕하세요 {name} 님</SectionTitle>
            <Pre>{`
            6월에 이어 7월까지, 쉘하우스에서는 각자의 취향과 성격을 생각해보고 나 자신을 책에 투영해 소개해보는 시간을 가졌습니다.

            이제는 다시 책으로 돌아와 이야기를 이어가 볼 때가 된 것 같습니다.
            `}</Pre>
          </VerticalBox>
        );
      case 3:
        return (
          <VerticalBox>
            <Pre>{`
            쉘하우스에서는 4개월 동안 매 달 1 권씩 총 4권의 책을 추천할 예정입니다.

            1권의 책마다 우리가 책을 읽는 이유 1가지를 주제로 다룰 예정입니다.
            
            우리의 삶에는 다양한 이유로 책이 필요합니다. 그 이유들이 책에 대한 관점을 구성합니다.
            
            여러분이 책을 통해 새로운 관점을 발견하고, 몰랐던 독서의 재미를 체험하는 기회가 되길 바랍니다.
            `}</Pre>
          </VerticalBox>
        );
      case 4:
        return (
          <VerticalBox>
            <Pre>{`
            8월에 소개할 관점은 ‘시선의 확장’입니다.

            <눈먼 자들의 도시>를 읽은 사람과 안 읽은 사람 사이의 가장 두드러지는 차이점은 ‘눈먼 자들이 사는 도시를 상상할 수 있는 가능성’일 겁니다.
            
            완전히 생소한 환경을 상상하며 그 속에서 내가 취할 수 있는 태도를 생각하는 경험은 익숙하지 않은 환경에서도 나의 가치관을 지킬 수 있는 힘으로 자리 잡을 것입니다.
            `}</Pre>
          </VerticalBox>
        );

      case 5:
        return (
          <VerticalBox>
            <SectionTitle>{month_kr} 추천 책 목록</SectionTitle>
            <Divider />
            {Object.entries(booklist).map(([k, v]) => {
              return <Book key={k} objKey={k} bookInfo={v} />;
            })}
            <Divider />
            <div
              style={{
                backgroundColor: "#c7c9cc",
                padding: "20px 15px",
                borderRadius: "5px",
                margin: "10px 0px",
              }}
            >
              <Pre>{`📖 <아노말리> 
              
              제안서를 제출하고 열흘 후인 2002년 4월, 국방부는 빨간 사인펜으로 다음과 같이 써서 문건을 돌려보낸다. ‘여기서 고려한 상황 중 어느 것에도 속하지 않을 경우는 어떻게 할 건지?’
              
              티나가 하늘을 쳐다보면서 말한다. “동전을 던졌는데 허공에 멈춰 서 있는 경우를 가정하라는 건가.”`}</Pre>
            </div>
            <div
              style={{
                backgroundColor: "#c7c9cc",
                padding: "20px 15px",
                borderRadius: "5px",
                margin: "10px 0px",
              }}
            >
              <Pre>{`📖 <아노말리> 

우리는 우리의 착각을 증명하는 모든 것에 눈을 감고 삽니다.`}</Pre>
            </div>
          </VerticalBox>
        );
      default:
        return (
          <HorizontalBox>
            <Info>안녕하세요 !</Info>
            <Logo src="/charactor1.png" alt="logo" />
          </HorizontalBox>
        );
    }
  };

  return (
    <GAHoc>
      <ProgressBar progress={progress * 100} />
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title={`${month_kr} 책추천`} />
        <StateResponder state={dispState} />
      </Container>
    </GAHoc>
  );
};
export default BooklistAugust;
