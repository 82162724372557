import React from "react";
import GAHoc from "../../GA";
import { Container } from "../../Components/Layout";
import HeaderTitle from "../../Components/Tilte";

const Meetup = () => {
  return (
    <GAHoc>
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title="대면모임 소개" />
      </Container>
    </GAHoc>
  );
};

export default Meetup;
