export const toNaver = () => {
  window.location.replace(
    `https://new-m.pay.naver.com/remit/qr/inflow?v=1&a=1002133822329&c=020&d=ad7f45ed74d9ad4643a9e991de151956`
  );
};
export const toPaypal = () => {
  window.location.replace("https://www.paypal.com/ncp/payment/LLAG6R72VHCLS");
};
export const toPaypalSub = () => {
  window.location.replace(
    "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-5KS63123TG395143UM2Y2X7A"
  );
};

export const toKakao = (reqPeriod) => {
  switch (reqPeriod) {
    case 1:
      window.location.replace(`https://qr.kakaopay.com/Ej7sKGVNz138801645`);
      break;
    case 2:
      window.location.replace(`https://qr.kakaopay.com/Ej7sKGVNz271002714`);
      break;
    case 3:
      window.location.replace(`https://qr.kakaopay.com/Ej7sKGVNz3a9801670`);
      break;
    case 4:
      window.location.replace(`https://qr.kakaopay.com/Ej7sKGVNz4e2005886`);
      break;
    case 5:
      window.location.replace(`https://qr.kakaopay.com/Ej7sKGVNz61a805244`);
      break;
    case 6:
      window.location.replace(`https://qr.kakaopay.com/Ej7sKGVNz753005971`);
      break;
    case 12:
      window.location.replace(`https://qr.kakaopay.com/Ej7sKGVNzc35003851`);
      break;

    default:
      window.location.replace(`https://qr.kakaopay.com/Ej7sKGVNz138801645`);
      break;
  }
};
