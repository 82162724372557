import React from "react";
import { Container } from "../Components/Layout";
import GAHoc from "../GA";
import { isIOS, isAndroid } from "react-device-detect";

const RedirectInsta = () => {
  if (isIOS) {
    window.location.replace("instagram://user?username=boogibooks");
  } else if (isAndroid) {
    window.location.replace(
      "intent://instagram.com/_u/boogibooks/#Intent;package=com.instagram.android;scheme=https;end"
    );
  } else {
    window.location.replace("https://instagram.com/boogibooks");
  }
  return (
    <GAHoc>
      <Container style={{ height: "100%" }}></Container>
    </GAHoc>
  );
};
export default RedirectInsta;
