import memberData from "../Data/member.json";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "../Components/Layout";
import {
  P,
  Pre,
  TextInput,
  Info,
  HorizontalBox,
  VerticalBox,
  SectionTitle,
} from "../Components/Text";
import HeaderTitle from "../Components/Tilte";
import GAHoc from "../GA";
import Logo from "../Components/Logo";
import { SelectBtn } from "../Components/Button";
import bookData from "../Data/books.json";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import route from "../route";
import { expired } from "../Components/Utils";

let result = 0;

const Image = styled.img`
  width: 125px;
  height: 183px;
  max-width: 128px;
  max-height: 183px;
  margin-top: 25px;
`;

const BooklistMay = () => {
  const [ansState, setAnsState] = useState([]);
  const [name, setName] = useState("부기");
  const history = useHistory();
  const bookMay = bookData.may.booklist;
  const [dispState, setDispState] = useState(expired(5) ? 2 : 0);
  useEffect(() => {
    const changeDisp = () => {
      return setTimeout(() => setDispState(dispState + 1), 2000);
    };
    if (dispState === 0) {
      changeDisp();
      return () => clearTimeout(changeDisp);
    } else if (dispState === 2) {
      changeDisp();
      return () => clearTimeout(changeDisp);
    } else {
      return;
    }
  }, [dispState]);
  const inputRef = useRef();
  useEffect(() => {
    if (dispState === 1) {
      inputRef.current.focus();
    }
    if (!expired(5) && !name && dispState > 1) {
      setDispState(1);
    }
  }, [name, dispState]);

  const changeHandler = ({ target: { value } }) => {
    if (Object.keys(memberData).includes(value)) {
      setName(value);
      setDispState(2);
    }
  };
  const StateResponder = ({ state }) => {
    switch (state) {
      case 0:
        return (
          <HorizontalBox>
            <Info>5월의 추천 책</Info>
            <Logo src="/charactor1.png" alt="logo" />
          </HorizontalBox>
        );
      case 1:
        return (
          <VerticalBox>
            <SectionTitle>{"사용자의 닉네임을"}</SectionTitle>
            <SectionTitle>{"입력하세요"}</SectionTitle>
            <TextInput
              ref={inputRef}
              type="text"
              onChange={changeHandler}
              placeholder={"닉네임"}
            />
          </VerticalBox>
        );
      case 2:
        return (
          <VerticalBox>
            <SectionTitle>안녕하세요 {name} 님</SectionTitle>
            <Pre>{`
            3가지 질문을 준비했습니다.
            
            답변에 따라 달라지는 
            추천 책을 확인하세요 !
            `}</Pre>
          </VerticalBox>
        );
      case 3:
        return (
          <VerticalBox>
            <SectionTitle>질문 1</SectionTitle>
            <Pre>
              {`
              ${name} 님의 관심사는 어느 쪽에 가깝나요?
            `}
            </Pre>
            <HorizontalBox>
              <SelectBtn
                onClick={() => {
                  setAnsState([...ansState, "돈"]);
                  result += 0;
                  setDispState(4);
                }}
              >
                돈
              </SelectBtn>
              <SelectBtn
                onClick={() => {
                  setAnsState([...ansState, "예술"]);
                  result += 2;
                  setDispState(4);
                }}
              >
                예술
              </SelectBtn>
            </HorizontalBox>
          </VerticalBox>
        );
      case 4:
        return (
          <VerticalBox>
            <SectionTitle>질문 2</SectionTitle>
            <Pre>
              {`
              ${name} 님은 어떤게 더 궁금한가요?

            `}
            </Pre>
            <P>{`A: ${ansState[0]}에 관심을 갖는 내가 어떻게 보일까?`}</P>
            <P>{`B: ${ansState[0]}에 대해 사람들은 어떻게 생각할까?`}</P>
            <HorizontalBox>
              <SelectBtn
                onClick={() => {
                  setAnsState([...ansState, "보여지는 것"]);
                  result += 4;
                  setDispState(5);
                }}
              >
                A
              </SelectBtn>
              <SelectBtn
                onClick={() => {
                  setAnsState([...ansState, "보는 것"]);
                  result += 1;
                  setDispState(5);
                }}
              >
                B
              </SelectBtn>
            </HorizontalBox>
          </VerticalBox>
        );
      case 5:
        return (
          <VerticalBox>
            <SectionTitle>질문 3</SectionTitle>
            <Pre>
              {`
              ${name} 님에게는 무엇이 더 중요한가요?

            `}
            </Pre>
            <P>{`A: 내가 바라는 세상의 모습`}</P>
            <P>{`B: 주변 사람들이 나에게 기대하는 역할`}</P>
            <HorizontalBox>
              <SelectBtn
                onClick={() => {
                  setAnsState([...ansState, "세계"]);
                  result += 3;
                  setDispState(6);
                }}
              >
                A
              </SelectBtn>
              <SelectBtn
                onClick={() => {
                  setAnsState([...ansState, "역할"]);
                  result += 10;
                  setDispState(6);
                }}
              >
                B
              </SelectBtn>
            </HorizontalBox>
          </VerticalBox>
        );
      case 6:
        return (
          <VerticalBox>
            <VerticalBox
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <SectionTitle>{name}님의 5월 추천 책</SectionTitle>
              <a
                href={bookMay[result].link}
                style={{ alignItems: "center", justifyContent: "space-around" }}
              >
                <Image src={bookMay[result].img} />
              </a>
              <a
                href={bookMay[result].link}
                style={{ alignItems: "center", justifyContent: "space-around" }}
              >
                <Pre>{bookMay[result].title}</Pre>
              </a>
              <P>분량: {bookMay[result].page}P</P>
            </VerticalBox>
            <Pre style={{ fontSize: "16px" }}>{bookMay[result].intro}</Pre>
            <VerticalBox>
              {memberData[name].recommanded ? (
                <SelectBtn
                  style={{ width: "100%" }}
                  onClick={() => history.push(`/book_recomm/${name}`)}
                >
                  {name} 님을 위한 추천
                </SelectBtn>
              ) : null}
              <SelectBtn
                style={{ width: "100%" }}
                onClick={() => history.push(route.booklist_all)}
              >
                다른 책 보기
              </SelectBtn>
            </VerticalBox>
          </VerticalBox>
        );
      default:
        return (
          <HorizontalBox>
            <Info>안녕하세요 !</Info>
            <Logo src="/charactor1.png" alt="logo" />
          </HorizontalBox>
        );
    }
  };

  return (
    <GAHoc>
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title="5월 책추천" />
        <StateResponder state={dispState} />
      </Container>
    </GAHoc>
  );
};
export default BooklistMay;
