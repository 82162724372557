import React from "react";
import { LinkBtn } from "../Components/Button";
import { Container } from "../Components/Layout";
import Logo from "../Components/Logo";
import { Info, MainBox } from "../Components/Text";
import HeaderTitle from "../Components/Tilte";
import GAHoc from "../GA";
import styled from "styled-components";
import route from "../route";
const LinkBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  max-width: 400px;
`;

const Home = () => {
  const toBooklist = () => {
    window.location.href = route.booklist_complex;
  };
  const toShell = () => {
    window.location.href = "https://bit.ly/boogibooks-intro";
  };
  const toJoin = () => {
    window.location.href = route.join;
  };
  return (
    <GAHoc>
      <Container style={{ height: "100%" }}>
        <HeaderTitle title="홈" />
        <MainBox>
          <Logo src="/logo.png" alt="logo" style={{ marginBottom: 30 }} />
          <Info>안녕하세요 ! 독서모임 쉘하우스입니다.</Info>
          <LinkBox>
            <LinkBtn onClick={toBooklist}>쉘하우스 추천 책 목록</LinkBtn>
            <LinkBtn onClick={toShell}>독서모임 쉘하우스 소개</LinkBtn>
            <LinkBtn onClick={toJoin}>쉘하우스 입주하기</LinkBtn>
          </LinkBox>
        </MainBox>
      </Container>
    </GAHoc>
  );
};
export default Home;
