import React from "react";
import { HorizontalBox, Info } from "./Text";

const TitleSection = ({ month_kr }) => {
  return (
    <HorizontalBox>
      <Info>{month_kr}의 추천 책</Info>
    </HorizontalBox>
  );
};
export default TitleSection;
