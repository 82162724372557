import memberData from "../Data/member.json";
import React, { useEffect, useRef, useState } from "react";
import { Container, Divider } from "../Components/Layout";
import {
  Pre,
  TextInput,
  Info,
  HorizontalBox,
  VerticalBox,
  SectionTitle,
  PGray,
} from "../Components/Text";
import HeaderTitle from "../Components/Tilte";
import GAHoc from "../GA";
import Logo from "../Components/Logo";
import bookData from "../Data/books.json";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import HiddenBook from "../Components/HiddeenBook";
import ProgressBar from "../Components/ProgressBar";
import TitleSection from "../Components/TitleSection";

const BooklistJune = () => {
  const [dispState, setDispState] = useState(0);
  const [name, setName] = useState("");
  const [progress, setProgress] = useState(0);
  const { month } = useParams();
  const { kr: month_kr, booklist } = bookData[month];
  const progressUpdate = (num) => {
    if (num < 1.0005) setProgress(num);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispState]);

  useEffect(() => {
    const greeting = () => {
      const greetingTimerId = setTimeout(() => {
        progressUpdate(progress + 1 / 1000);

        if (progress >= 1) {
          setProgress(0);
          clearTimeout(greetingTimerId);
          setDispState(dispState + 1);
        }
      }, 1);
      return greetingTimerId;
    };
    const changeDisp = () => {
      const changeDispTimerID = setTimeout(() => {
        progressUpdate(progress + 1 / 2000);

        if (progress >= 1 && progress < 1.0005) {
          setProgress(0);
          clearTimeout(changeDispTimerID);
          setDispState(dispState + 1);
        }
      }, 1);
      return changeDispTimerID;
    };

    if (dispState === 0) {
      greeting();
      return () => clearTimeout(greeting());
    } else if (2 <= dispState && dispState <= 5) {
      changeDisp();
    }
  }, [dispState, progress]);
  const inputRef = useRef();
  useEffect(() => {
    if (dispState === 1) {
      inputRef.current.focus();
    }
    if (!name && dispState > 1) {
      setDispState(1);
    }
  }, [name, dispState]);

  const changeHandler = ({ target: { value } }) => {
    if (Object.keys(memberData).includes(value)) {
      setName(value);
      setDispState(2);
    }
  };
  const StateResponder = ({ state }) => {
    switch (state) {
      case 0:
        return <TitleSection month_kr={month_kr} />;
      case 1:
        return (
          <VerticalBox>
            <SectionTitle>{"사용자의 닉네임을"}</SectionTitle>
            <SectionTitle>{"입력하세요"}</SectionTitle>
            <TextInput
              ref={inputRef}
              type="text"
              onChange={changeHandler}
              placeholder={"닉네임"}
            />
          </VerticalBox>
        );
      case 2:
        return (
          <VerticalBox>
            <SectionTitle>안녕하세요 {name} 님</SectionTitle>
            <Pre>{`
            이번 달 쉘하우스에서는
            '${name} 님의 이야기' 대신,

            '다른 사람과 연결되는 이야기'를 
            주제로 하려 합니다.
            `}</Pre>
          </VerticalBox>
        );
      case 3:
        return (
          <VerticalBox>
            <Pre>{`
            다른 사람의 시선을 빌리고,
            우리의 방향성을 만들어 가는
            
            그런 일의 아름다움에 대해서요.
            `}</Pre>
          </VerticalBox>
        );
      case 4:
        return (
          <VerticalBox>
            <Pre>{`
            어떤 단어가 어울릴까 고민하다가
            마침 지난 번 대면모임에서 언급된;

            '공명'이라는 단어가
            꼭 어울린다고 생각했습니다.
            `}</Pre>
          </VerticalBox>
        );
      case 5:
        return (
          <VerticalBox>
            <SectionTitle>{"6월 키워드: 공명"}</SectionTitle>
            <Pre>{`
            각자의 방향성과, 각자의 시선이
            서로 공명하는 시간을 기대하면서

            부기는 몇권의 책을 골라 담았습니다.
            `}</Pre>
          </VerticalBox>
        );

      case 6:
        return (
          <VerticalBox>
            <SectionTitle>{month_kr} 추천 책 목록</SectionTitle>
            <PGray>가장 끌리는 책 한 권을 골라 선택해주세요 🐢</PGray>
            <Divider />
            {Object.entries(booklist).map(([k, v]) => {
              return <HiddenBook key={k} objKey={k} bookInfo={v} />;
            })}
          </VerticalBox>
        );
      default:
        return (
          <HorizontalBox>
            <Info>안녕하세요 !</Info>
            <Logo src="/charactor1.png" alt="logo" />
          </HorizontalBox>
        );
    }
  };

  return (
    <GAHoc>
      <ProgressBar progress={progress * 100} />
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title={`${month_kr} 책추천`} />
        <StateResponder state={dispState} />
      </Container>
    </GAHoc>
  );
};
export default BooklistJune;
