import React, { useEffect, useRef, useState } from "react";
import { Container, Divider } from "../Components/Layout";
import {
  Pre,
  TextInput,
  Info,
  HorizontalBox,
  VerticalBox,
  SectionTitle,
  P,
} from "../Components/Text";
import HeaderTitle from "../Components/Tilte";
import GAHoc from "../GA";
import Logo from "../Components/Logo";
import bookData from "../Data/books.json";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import ProgressBar from "../Components/ProgressBar";
import TitleSection from "../Components/TitleSection";
import Book from "../Components/Book";
import TurningPage from "../Components/TurningPage";
import { changeHandler } from "../Components/MemberChecker";
import { expired } from "../Components/Utils";

const StateResponder = ({
  state,
  goNext,
  goPrev,
  inputRef,
  month_kr,
  name,
  booklist,
  setProgress,
  setName,
  setDispState,
}) => {
  switch (state) {
    case 0:
      return (
        <VerticalBox>
          <TitleSection month_kr={month_kr} />
          <P>{"사용자의 닉네임을 입력하세요"}</P>
          <TextInput
            ref={inputRef}
            type="text"
            onChange={(e) => changeHandler(e, setName, setDispState, 1)}
            placeholder={"닉네임"}
          />
        </VerticalBox>
      );
    case 1:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} first>
          <VerticalBox>
            <SectionTitle>안녕하세요 {name} 님</SectionTitle>
            <Pre>{`
9월에도 여러분과 함께 한 권의 책을 읽으며 하나의 주제를 다룹니다.

이번 달에도 우리가 책을 통해 얻을 수 있는 것에 대해 이야기합니다.

9월의 주제는 '학습'입니다.
`}</Pre>
          </VerticalBox>
        </TurningPage>
      );
    case 2:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} setProgress={setProgress}>
          <Pre>{`
학습;

어쩌면 우리가 가장 처음 책을 접한 이유이자, 여전히 책을 찾게 되는 가장 큰 이유일겁니다.

우리는 보통 무언가 새로운 것을 배우고 삶에 적용해 더 발전하기 위해 책을 폅니다.

책에는 우리가 만날 수 없는 전문가들의 노하우와 지식이 집약되어 글의 형태로 담겨있기 때문이죠.
          `}</Pre>
        </TurningPage>
      );
    case 3:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev}>
          <Pre>{`
다만 여러분이 궁금해하고 배우고 싶어 하는 것들이 모두 다를테니,

가장 보편적으로 사람들이 배우고 싶어하고 또 배우면 좋은 것을 고르느라 많이 고민했습니다.

고민 끝에 고른 주제는 [대화]입니다. 아무래도 관계과 대화는 우리 모두에게 중요한 것이니까요.

모각독에 들어오신 분들은 이미 보고 알고 계시듯 9월의 추천 책은 최재천 교수님의 신작입니다.
`}</Pre>
        </TurningPage>
      );

    case 4:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} last>
          <VerticalBox>
            <SectionTitle>{month_kr} 추천 책 목록</SectionTitle>
            <Divider />
            {Object.entries(booklist).map(([k, v]) => {
              return <Book key={k} objKey={k} bookInfo={v} />;
            })}
            <Divider />
            <div
              style={{
                backgroundColor: "#c7c9cc",
                padding: "20px 15px",
                borderRadius: "5px",
                margin: "10px 0px",
              }}
            >
              <Pre>{`📖 <숙론> 
            
나는 미국 어느 인디언 보호 구역의 학교에 새로 부임한 백인 교사의 일화를 늘 가슴에 품고 산다. 

시험을 시작하겠다고 하니 아이들이 홀연 둥그렇게 둘러앉더란다. 

시험을 봐야 하니 서로 떨어져 앉으라고 했더니 아이들은 어리둥절해하며 이렇게 말하더란다. 

“저희들은 어른들에게서 어려운 일이 생기면 함께 상의하라고 배웠는데요.”`}</Pre>
            </div>
            <div
              style={{
                backgroundColor: "#c7c9cc",
                padding: "20px 15px",
                borderRadius: "5px",
                margin: "10px 0px",
              }}
            >
              <Pre>{`📖 <숙론> 

숙론 흐름표는 일렬로 나열된 도표가 아니라 가치러첨 뻗은 분지형이어야 한다.

숙론은 그 자체가 살아 있는 유기체와 같다.

주제의 속성과 참여자들의 성향에 따라 어디로 튈지 모르는 역동적인 시스템이다.`}</Pre>
            </div>
          </VerticalBox>
        </TurningPage>
      );
    default:
      return (
        <HorizontalBox>
          <Info>안녕하세요 !</Info>
          <Logo src="/charactor1.png" alt="logo" />
        </HorizontalBox>
      );
  }
};

const BooklistSep = () => {
  const [name, setName] = useState("부기");
  const [progress, setProgress] = useState(0);
  const { month } = useParams();
  const { kr: month_kr, booklist } = bookData[month];
  const [dispState, setDispState] = useState(expired(month_kr[0]) ? 1 : 0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispState]);
  useEffect(() => {
    setProgress(dispState / 4);
  }, [dispState]);
  const inputRef = useRef();
  useEffect(() => {
    if (dispState === 0) {
      inputRef.current.focus();
    }
    if ((!expired(month_kr[0]), name === "부기" && dispState > 0)) {
      setDispState(0);
    }
  }, [name, dispState, month_kr]);

  const goNext = () => {
    setDispState(dispState + 1);
  };
  const goPrev = () => {
    setDispState(dispState - 1);
  };

  return (
    <GAHoc>
      <ProgressBar progress={progress * 100} />
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title={`${month_kr} 책추천`} />
        <StateResponder
          state={dispState}
          goNext={goNext}
          goPrev={goPrev}
          inputRef={inputRef}
          setDispState={setDispState}
          setName={setName}
          month_kr={month_kr}
          name={name}
          booklist={booklist}
          setProgress={setProgress}
        />
      </Container>
    </GAHoc>
  );
};
export default BooklistSep;
