import emailjs from "@emailjs/browser";
import React, { useEffect, useRef, useState } from "react";
import { FormBtn, LinkBtn } from "../Components/Button";
import { Container } from "../Components/Layout";
import ProgressBar from "../Components/ProgressBar";
import {
  HorizontalBox,
  P,
  PGray,
  Pre,
  SectionTitle,
  TextInput,
  VerticalBox,
} from "../Components/Text";
import HeaderTitle from "../Components/Tilte";
import GAHoc from "../GA";
import { toKakao, toNaver, toPaypal } from "../Components/Utils";
const Join = () => {
  const [dispState, setDispState] = useState("intro");
  const [name, setName] = useState("");
  const [instaID, setInstaID] = useState("");
  const [q1Ans, setQ1Ans] = useState("");
  const [q2Ans, setQ2Ans] = useState("");
  const [q3Ans, setQ3Ans] = useState("");
  const [progress, setProgress] = useState(0);
  const [foreign, setForeign] = useState(false);
  const inputRef = useRef();
  const progressUpdate = (num) => {
    if (num < 1.0005) setProgress(num);
  };
  const sendRes = (referee) => {
    emailjs
      .send(
        "service_ynqt3v5",
        "template_t9194th",
        {
          name,
          instaID,
          q1Ans,
          q2Ans,
          q3Ans,
          referee,
        },
        "sDbK-2vvlIKrTJtdY"
      )
      .then(
        () => console.log("ok 200"),
        (err) => console.log(err)
      );
  };
  useEffect(() => {
    const greeting = () => {
      const greetingTimerId = setTimeout(() => {
        progressUpdate(progress + 1 / 1000);

        if (progress >= 1) {
          setProgress(0);
          clearTimeout(greetingTimerId);
          setDispState("name");
        }
      }, 1);
      return greetingTimerId;
    };
    if (dispState === "intro") {
      greeting();
      return () => clearTimeout(greeting());
    }
  }, [dispState, progress]);

  const StateResponder = ({ state }) => {
    switch (state) {
      case "intro":
        return (
          <VerticalBox>
            <SectionTitle>{"환영합니다 !"}</SectionTitle>
          </VerticalBox>
        );
      case "name":
        return (
          <VerticalBox>
            <SectionTitle>{"닉네임 제출"}</SectionTitle>
            <Pre>{"쉘하우스 공간에서 사용하실 닉네임을 알려주세요."}</Pre>
            <PGray>{"별명, 실명 무엇을 사용해도 상관 없습니다."}</PGray>
            <HorizontalBox>
              <TextInput ref={inputRef} type="text" placeholder={"닉네임"} />
              <FormBtn
                onClick={() => {
                  setName(inputRef.current.value);
                  if (inputRef.current.value === "") {
                    alert("닉네임이 제공되지 않았습니다.");
                    return;
                  } else if (inputRef.current.value === "test") {
                    setDispState("pay");
                    return;
                  }
                  setDispState("insta");
                }}
              >
                제출
              </FormBtn>
            </HorizontalBox>
          </VerticalBox>
        );
      case "insta":
        return (
          <VerticalBox>
            <SectionTitle>{"인스타그램 ID"}</SectionTitle>
            <Pre>
              {
                "인스타그램 ID를 남겨주세요. 신청 확인 후 쉘하우스 이용을 도와드리기 위해 DM을 이용해 연락을 드리고 있습니다."
              }
            </Pre>
            <HorizontalBox>
              <TextInput
                ref={inputRef}
                type="text"
                placeholder={"인스타그램 ID"}
              />
              <FormBtn
                onClick={() => {
                  setInstaID(inputRef.current.value);
                  if (inputRef.current.value === "") {
                    alert("인스타그램 ID가 제공되지 않았습니다.");
                    return;
                  }
                  setDispState("Q1");
                }}
              >
                제출
              </FormBtn>
            </HorizontalBox>
          </VerticalBox>
        );
      case "Q1":
        return (
          <VerticalBox>
            <SectionTitle>{"취향"}</SectionTitle>
            <Pre>{`${name} 님이 가장 좋아하는 활동은 어떤 것인가요?`}</Pre>
            <FormBtn
              onClick={(e) => {
                setQ1Ans(e.target.textContent);
                setDispState("Q2");
              }}
            >
              옷, 가구, 소품 쇼핑 등 수집 활동
            </FormBtn>
            <FormBtn
              onClick={(e) => {
                setQ1Ans(e.target.textContent);
                setDispState("Q2");
              }}
            >
              영화 감상, 음악 감상 등 수동적 체험
            </FormBtn>
            <FormBtn
              onClick={(e) => {
                setQ1Ans(e.target.textContent);
                setDispState("Q2");
              }}
            >
              전시 관람, 스포츠 경기 관람 등 능동적 체험
            </FormBtn>
            <FormBtn
              onClick={(e) => {
                setQ1Ans(e.target.textContent);
                setDispState("Q2");
              }}
            >
              모임, 단체 운동 경기 등 외부 활동
            </FormBtn>
            <FormBtn
              onClick={(e) => {
                setQ1Ans(e.target.textContent);
                setDispState("Q2");
              }}
            >
              요리, 원데이클래스 등 공예 활동
            </FormBtn>
            <FormBtn
              onClick={(e) => {
                setQ1Ans(e.target.textContent);
                setDispState("Q2");
              }}
            >
              글쓰기, 그림그리기 등 창작 활동
            </FormBtn>
          </VerticalBox>
        );
      case "Q2":
        return (
          <VerticalBox>
            <SectionTitle>{"표현"}</SectionTitle>
            <Pre>
              {`${name} 님이 스스로를 표현할 때 가장 편하게 사용하는 방식은 무엇인가요?`}
            </Pre>
            <FormBtn
              onClick={(e) => {
                setQ2Ans(e.target.textContent);
                setDispState("Q3");
              }}
            >
              사진, 영상 등의 시각 자료
            </FormBtn>
            <FormBtn
              onClick={(e) => {
                setQ2Ans(e.target.textContent);
                setDispState("Q3");
              }}
            >
              묘사를 담은 글
            </FormBtn>
            <FormBtn
              onClick={(e) => {
                setQ2Ans(e.target.textContent);
                setDispState("Q3");
              }}
            >
              상대를 마주하고 전하는 말
            </FormBtn>
            <FormBtn
              onClick={(e) => {
                setQ2Ans(e.target.textContent);
                setDispState("Q3");
              }}
            >
              숫자를 포함해 정리된 데이터
            </FormBtn>
          </VerticalBox>
        );
      case "Q3":
        return (
          <VerticalBox>
            <SectionTitle>{"호기심"}</SectionTitle>
            <Pre>
              {`${name} 님이 근래 가장 많이 찾아보고 궁금해하는 것은 무엇인가요?`}
            </Pre>
            <HorizontalBox>
              <TextInput
                ref={inputRef}
                type="text"
                placeholder={"최근의 호기심"}
              />
              <FormBtn
                onClick={() => {
                  setQ3Ans(inputRef.current.value);
                  setDispState("referee");
                }}
              >
                제출
              </FormBtn>
            </HorizontalBox>
          </VerticalBox>
        );
      case "referee":
        return (
          <VerticalBox>
            <SectionTitle>{"쉘하우스 추천인"}</SectionTitle>
            <Pre>
              {`지인을 통해 쉘하우스에 등록하게 되셨나요 ? \n\n${name}님과 추천 멤버 두 분 모두에게 대면모임 비용 할인 혜택을 제공해드립니다. \n\n쉘하우스를 추천한 지인의 닉네임을 알려주세요 !`}
            </Pre>
            <PGray>(추천인이 없을 경우에는 공란으로 넘어가시면 됩니다)</PGray>
            <HorizontalBox>
              <TextInput
                ref={inputRef}
                type="text"
                placeholder={"추천인의 쉘하우스 닉네임"}
              />
              <FormBtn
                onClick={() => {
                  sendRes(inputRef.current.value);
                  setDispState("pay");
                }}
              >
                제출
              </FormBtn>
            </HorizontalBox>
          </VerticalBox>
        );
      case "pay":
        return (
          <VerticalBox>
            <SectionTitle>{`${name}님의 입주 등록`}</SectionTitle>
            <Pre>{`
              쉘하우스에 입주자로 등록되면 대면모임을 포함한 모든 활동에 참여할 수 있습니다.
              `}</Pre>
            <P>
              {`
                  쉘하우스에서는 매달 읽을만한 책을 추천하고, 책 이야기를 엮은 읽을거리를 발행해 제공하고 있습니다.
                  `}
            </P>
            <P>
              쉘하우스에서는 1달 간의 체험 이후 이용 연장을 결정하실 수
              있습니다. 월 이용료는 1만원 입니다.
            </P>
            {foreign ? (
              <>
                <LinkBtn onClick={() => toPaypal()}>페이팔로 송금</LinkBtn>
                <PGray>
                  {
                    "해외 송금은 페이팔을 이용할 수 있습니다.\n\n대면모임에 참가하기 어려운 상황을 고려해, 기존보다 할인된 월 $5에 이용하실 수 있습니다."
                  }
                </PGray>
              </>
            ) : (
              <>
                <LinkBtn onClick={() => toNaver()}>네이버 페이로 송금</LinkBtn>
                <LinkBtn onClick={() => toKakao(1)}>카카오 페이로 송금</LinkBtn>
                <PGray>
                  간편송금 이용이 어려운 분들은 아래의 계좌번호로 직접
                  입금해주세요.
                </PGray>
                <PGray>(1002-133-822329 우리은행)</PGray>
              </>
            )}
            <PGray>
              신청 확인 후 인스타그램을 통해 DM을 보내드리고 있습니다.
            </PGray>
            <PGray>신청 확인에는 최대 1일 정도가 소요됩니다.</PGray>
            {foreign ? (
              <PGray
                onClick={() => setForeign(false)}
                style={{ fontSize: "10px", cursor: "pointer" }}
              >
                ✔️ 국내에 거주 중이신가요 ?
              </PGray>
            ) : (
              <PGray
                onClick={() => setForeign(true)}
                style={{ fontSize: "10px", cursor: "pointer" }}
              >
                ✔️ 해외에 거주 중이신가요 ?
              </PGray>
            )}
          </VerticalBox>
        );
      default:
        setDispState("name");
        return <VerticalBox>default</VerticalBox>;
    }
  };
  return (
    <GAHoc>
      <ProgressBar progress={progress * 100} />
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title="입주 | 쉘하우스" />
        <StateResponder state={dispState} />
      </Container>
    </GAHoc>
  );
};
export default Join;
