import React, { useState } from "react";
import GAHoc from "../../GA";
import { Container } from "../../Components/Layout";
import HeaderTitle from "../../Components/Tilte";
import {
  P,
  PGray,
  Pre,
  SectionTitle,
  VerticalBox,
} from "../../Components/Text";
import { LinkBtn } from "../../Components/Button";
import { sendPayEmail } from "../../Components/Utils";

const Pay = () => {
  const [foreign, setForeign] = useState(false);
  const params = new URLSearchParams(document.location.search);
  const name = params.get("name");
  const payHandler = (platform) => {
    sendPayEmail(name, 1, platform);
  };

  return (
    <GAHoc>
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title="입주 | 쉘하우스" />

        <VerticalBox>
          <SectionTitle>{`쉘하우스 이용료 결제`}</SectionTitle>
          <Pre>{`
              쉘하우스에 입주자로 등록되면 대면모임을 포함한 모든 활동에 참여할 수 있습니다.
              `}</Pre>
          <P>
            {`
                  쉘하우스에서는 매달 읽을만한 책을 추천하고, 책 이야기를 엮은 읽을거리를 발행해 제공하고 있습니다.
                  `}
          </P>
          <P>
            쉘하우스에서는 1달 간의 체험 이후 이용 연장을 결정하실 수 있습니다.
            월 이용료는 1만원 입니다.
          </P>
          {foreign ? (
            <>
              <LinkBtn onClick={() => payHandler("페이팔")}>
                페이팔로 송금
              </LinkBtn>
              <PGray>
                {
                  "해외 송금은 페이팔을 이용할 수 있습니다.\n\n대면모임에 참가하기 어려운 상황을 고려해, 기존보다 할인된 월 $5에 이용하실 수 있습니다."
                }
              </PGray>
            </>
          ) : (
            <>
              <LinkBtn onClick={() => payHandler("네이버")}>
                네이버 페이로 송금
              </LinkBtn>
              <LinkBtn onClick={() => payHandler("카카오")}>
                카카오 페이로 송금
              </LinkBtn>
              <PGray>
                간편송금 이용이 어려운 분들은 아래의 계좌번호로 직접
                입금해주세요.
              </PGray>
              <PGray>(1002-133-822329 우리은행)</PGray>
            </>
          )}
          <PGray>
            신청 확인 후 인스타그램을 통해 DM을 보내드리고 있습니다.
          </PGray>
          <PGray>신청 확인에는 최대 1일 정도가 소요됩니다.</PGray>
          {foreign ? (
            <PGray
              onClick={() => setForeign(false)}
              style={{ fontSize: "10px", cursor: "pointer" }}
            >
              ✔️ 국내에 거주 중이신가요 ?
            </PGray>
          ) : (
            <PGray
              onClick={() => setForeign(true)}
              style={{ fontSize: "10px", cursor: "pointer" }}
            >
              ✔️ 해외에 거주 중이신가요 ?
            </PGray>
          )}
        </VerticalBox>
      </Container>
    </GAHoc>
  );
};
export default Pay;
