import React from "react";
import { Container, Divider } from "../Components/Layout";
import { Pre, VerticalBox, SectionTitle } from "../Components/Text";
import HeaderTitle from "../Components/Tilte";
import GAHoc from "../GA";
import userData from "../Data/member.json";
import UserCard from "../Components/UserCard";

const BooklistUserlist = () => {
  return (
    <GAHoc>
      <Container style={{ paddingTop: "30px" }}>
        <HeaderTitle title={`입주자 목록`} />
        <VerticalBox>
          <SectionTitle>멤버들의 책 추천</SectionTitle>
          <Pre>{`
            눈길이 가는 문장을 눌러 멤버가 쓴 소개 글 전체를 읽어볼 수 있습니다.

            해당 멤버가 읽어 온 책 목록이 궁금하다면 화살표를 눌러 확인해보세요.
            `}</Pre>
          <Divider />
          {Object.entries(userData).map(([k, v]) => {
            if (v.accent) {
              return <UserCard key={k} objKey={k} user={{ name: k, ...v }} />;
            } else {
              return null;
            }
          })}
        </VerticalBox>
      </Container>
    </GAHoc>
  );
};
export default BooklistUserlist;
