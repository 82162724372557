import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import BooklistMay from "./BooklistMay";
import BooklistJune from "./BooklistJune";
import BooklistJuly from "./BooklistJuly";
import BooklistAugust from "./BooklistAugust";
import BooklistSep from "./BooklistSep";

const BooklistMonth = () => {
  const { month } = useParams();

  switch (month) {
    case "may":
      return <BooklistMay />;
    case "june":
      return <BooklistJune />;
    case "july":
      return <BooklistJuly />;
    case "aug":
      return <BooklistAugust />;
    case "sep":
      return <BooklistSep />;
    default:
      return <></>;
  }
};
export default BooklistMonth;
