import React, { useState } from "react";
import GAHoc from "../../GA";
import { Container, Divider } from "../../Components/Layout";
import HeaderTitle from "../../Components/Title";
import {
  HorizontalBox,
  P,
  SectionTitle,
  TextInput,
  VerticalBox,
} from "../../Components/Text";
import { LinkBtn } from "../../Components/Button";
import { sendMeetupEmail } from "../../Components/Utils";

const Meetup = () => {
  const [name, setName] = useState("");
  const [role, setRole] = useState("말하는 이");

  return (
    <GAHoc>
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title="대면모임 소개" />
        <VerticalBox>
          <SectionTitle>9월 대면모임</SectionTitle>
          <P>
            9월 대면모임에서는 1시간 동안의 미니 토론과, 2시간 동안의 숙론
            리뷰가 진행됩니다.
          </P>

          <P>
            이번 대면모임에서 멤버 분들은 '말하는 이'와 '묻는 이'로 구분된
            역할을 골라 참여하실 수 있습니다.
          </P>

          <P>
            말하는 이는 토론에 참여하며 이후 책 리뷰를 함께 진행합니다. 책을
            읽고 오신 분만 참여하실 수 있습니다.
          </P>

          <P>
            묻는 이는 진행되는 대면 모임을 한 발치 떨어져서 듣는 것으로 참여할
            수 있으며, 현장에서 채팅을 통해 말하는 이들에게 질문을 할 수
            있습니다.
          </P>
          <P>아래에 닉네임을 입력하고, 희망하는 역할을 선택해주세요.</P>
          <Divider />
          <TextInput
            id="data2"
            key="name_input_value"
            style={{
              width: "100%",
              textAlign: "start",
              padding: "10px 15px",
            }}
            type="text"
            placeholder={"디스코드 닉네임"}
            onChange={(e) => setName(e.target.value)}
          />
          <HorizontalBox>
            <div>
              <input
                type="radio"
                id="말하는 이"
                name="역할"
                value="말하는 이"
                onClick={() => setRole("말하는 이")}
                checked={role === "말하는 이" ? true : false}
              />
              <label for="말하는 이">말하는 이</label>
            </div>

            <div>
              <input
                type="radio"
                id="듣는 이"
                name="역할"
                value="듣는 이"
                onClick={() => setRole("듣는 이")}
                checked={role === "듣는 이" ? true : false}
              />
              <label for="듣는 이">듣는 이</label>
            </div>
          </HorizontalBox>
          {role === "말하는 이" ? (
            <P style={{ color: "#6a6a6a" }}>
              토론 주제: 숙론의 1부에는 7가지의 갈등이 숙제로 제안되고 있습니다.
              멤버 분이 생각하는 '가장 우선적으로 해결되어야 할 갈등'을 선택하고
              이유를 알려주세요.
            </P>
          ) : null}
          <LinkBtn onClick={() => sendMeetupEmail(name, role)}>
            대면모임 신청서 제출
          </LinkBtn>
        </VerticalBox>
      </Container>
    </GAHoc>
  );
};

export default Meetup;
