import React, { useEffect, useState } from "react";
import { Title } from "./Text";
import { Divider } from "./Layout";
import Book from "./Book";

const MonthlyList = ({ book_of_month }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (book_of_month.kr === "6월") {
      setShow(true);
    }
  }, [book_of_month.kr]);

  return (
    <>
      <Title
        onClick={() => setShow(!show)}
        style={{ textAlign: "left", padding: "0 25px", cursor: "pointer" }}
      >
        {book_of_month.kr}
      </Title>
      <Divider style={{ height: "3px" }} />
      {show
        ? Object.values(book_of_month.booklist).map((item) => {
            return (
              <>
                <Book bookInfo={item} key={item.title} />
                <Divider />
              </>
            );
          })
        : null}
    </>
  );
};
export default MonthlyList;
